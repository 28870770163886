@import url("https://fonts.googleapis.com/css2?family=Neucha&family=Poppins:wght@300&display=swap");

/* ======== Varirables ======== */
:root {
  --header-height: 3rem;
  /* ======== Colors ======== */
  --hue: 0;
  --sat: 0%;
  --border-cursor: hsl(var(--hue), var(--sat), 80%);
  --title-color: hsl(var(--hue), var(--sat), 20%);
  --title-color-dark: hsl(var(--hue), var(--sat), 0%);
  --text-color: hsl(var(--hue), var(--sat), 46%);
  --body-color: hsl(var(--hue), var(--sat), 98%);
  --container-color: #fff;
  --box-shadow-img: inset 9px 10px 10px 0.5px rgba(0, 0, 10, 0.21);
  --first-color: hsl(var(--hue), var(--sat), 50%);

  /* ======== Font and typography ======== */
  --body-font: "Poppins", sans-serif;
  /* .5rem = 8px | 1rem = 16px ... */
  --big-font-size: 3.5rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --tiny-font-size: 0.625rem;

  /* ======== Font weight ======== */

  --font-normal: 400;
  --font-meduim: 500;
  --font-semi-bold: 600;

  /* ======== Margenes Bottom ======== */
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;
  /* ======== Z-index ======== */
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

/* ======== Light an Dark ======== */
body.dark {
  --hue: 210;
  --sat: 20%;
  --title-color: hsl(var(--hue), var(--sat), 90%);
  --title-color-dark: hsl(var(--hue), var(--sat), 100%);
  --text-color: hsl(var(--hue), var(--sat), 70%);
  --body-color: hsl(var(--hue), var(--sat), 10%);
  --container-color: hsl(var(--hue), var(--sat), 20%);
  --border-cursor: hsl(var(--hue), var(--sat), 80%);
  --box-shadow-img: inset 9px 10px 10px 0.5px rgba(244, 244, 247, 0.21);
  --first-color: hsl(var(--hue), var(--sat), 50%);
}

/* Home Social Icons colors */
body.dark .home__social {
  color: hsl(var(--hue), var(--sat), 70%);
}
body.dark .home__social:hover {
  color: hsl(var(--hue), var(--sat), 90%);
}

/* home img shadow */
body.dark .home__img {
  box-shadow: inset 9px 10px 10px 0.5px rgba(244, 244, 247, 0.21);
}
/* Dark mode input field */
body.dark .contact__form-input {
  background-color: hsl(var(--hue), var(--sat), 20%);
  color: var(--title-color);
}
body.dark .contact__form-tag {
  color: var(--title-color);
  background-color: hsl(var(--hue), var(--sat), 20%);
  border-top-right-radius: 0.3rem;
  border-top-left-radius: 0.3rem;
}
body.dark ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--text-color);
  opacity: 1; /* Firefox */
}

body.dark :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--text-color);
}

body.dark ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--text-color);
}
/* Responsive Typography */
@media screen and (max-width: 993px) {
  :root {
    --big-font-size: 2.75rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h1-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813;
    --smaller-font-size: 0.75rem;
  }
}

/* ======== BASE======== */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}
body {
  background-color: var(--body-color);
  color: var(--text-color);
}

h1,
h2,
h3 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}
a {
  text-decoration: none;
  cursor: pointer;
}
button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* ======== RESUSABLE CSS CLASSES======== */
.section {
  padding: 6rem 0 2rem;
  min-height: 100vh;
}
.section__title {
  font-size: var(--h1-font-size);
  color: var(--title-color);
}

.section__subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: 4rem;
}
.section__title,
.section__subtitle {
  text-align: center;
}
/* ========  LAYOUT ======== */

.container {
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
}
.grid {
  display: grid;
  gap: 1.5rem;
}
/* ======== BUTTONS ======== */

.button {
  display: inline-block;
  background-color: var(--title-color);
  color: var(--container-color);
  padding: 1.25rem 2rem;
  border-radius: 1rem;
  font-weight: var(--font-meduim);
}
.button:hover {
  background-color: var(--title-color-dark);
}
.button__icon {
  margin-left: var(--mb-0-5);
}

.button--flex {
  display: inline-flex;
  align-items: center;
}

/* ======== BREAKPOINTS======== */

/* Large device */
@media screen and (max-width: 992px) {
  .container {
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
  }
  .button {
    padding: 1rem 1.75rem;
  }
  .button__icon {
    width: 22px;
    height: 22px;
  }
}
/* Meduim device */
@media screen and (max-width: 768px) {
  body {
    margin: 0 0 var(--header-height) 0;
  }
  .section {
    padding: 2rem 0 4rem;
  }
  .section__subtitle {
    margin-bottom: var(--mb-3);
  }
}
/* Meduim device */
@media screen and (max-width: 576px) {
}

/* Small device */
@media screen and (max-width: 350px) {
  :root {
    --big-font-size: 2.25rem;
  }
  .container {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }
}
